


















































import Vue from 'vue'
import api from '@/api'
import RecipientInfoBox from '@/components/RecipientInfoBox.vue'

interface RecipientInfo {
  firstname: string
  lastname: string
  msisdn: string
  profilename: string
  status: string
}

export default Vue.extend({
  components: {
    RecipientInfoBox
  },

  data() {
    return {
      isLoading: false,
      benef: {
        phone: ''
      },
      recipient: {},
      provider: ''
    }
  },

  computed: {
    valid(): boolean {
      return this.benef.phone.length === 10 && !isNaN(Number(this.benef.phone))
    }
  },

  methods: {
    async onSubmit(this: any) {
      this.toogleLoading()
      try {
        // 97457327
        const response = await api.beneficiaryKyc(this.benef.phone)

        this.toogleLoading()

        const recipient: RecipientInfo = response.data
        this.$nextTick(() => {
          this.$refs.form.reset()
        })

        this.recipient = recipient
        this.provider = response.data.provider
      } catch (error) {
        this.toogleLoading()
        // console.log('Error', error)
      }
    },
    toogleLoading() {
      this.isLoading = !this.isLoading
    }
  }
})
